.react-tag-input{
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: auto;
  min-height: 2.375em;
  padding: 0.1875em 0.1875em * 2;
  overflow-y: auto;

  display: flex;
  flex-wrap: wrap;
  align-items: center;

  font-size: 1rem;
  background: white;
  color: #333;
  border: 2px solid var(--light-gray);
  border-radius: 0.25rem;

  * { box-sizing: border-box; }

  > * { margin: 0.1875em; }

  @at-root #{&}__input {
    width: auto;
    flex-grow: 1;
    height: 2.375em - 0.5;
    padding: 0 0 0 0.1875em;
    margin: 0 0.1875em;
    background: transparent;
    color: #333;
    border: none;
    border-radius: 0.25rem;
    outline: 0;
    box-shadow: none;
    -webkit-appearance: none;

    &::placeholder, &:-moz-placeholder, &:-ms-input-placeholder, &::-moz-placeholder, &::-webkit-input-placeholder {
      color: #333;
    }
    &:focus {
      border: none;
    }
  }

  @at-root #{&}__tag {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 1em - 0.15;
    line-height: 1;
    background: #C0F2D8;
    border-radius: 0.25rem;
  }

  @at-root #{&}__tag__content {
    outline: 0;
    border: none;
    white-space: nowrap;
    padding: 0 0.1875em * 2.5;
  }

  @at-root #{&}__tag__remove {
    position: relative;
    height: 1em * 2;
    width: 1em * 2;

    font-size: 1em - 0.15;
    cursor: pointer;
    background: var(--light-gray);
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;

    &:before, &:after {
      position: absolute;
      top: 50%;
      left: 50%;
      content: ' ';
      height: 1em - 0.1;
      width: 0.15em;
      background-color: #333;
    }
    &:before {
      transform: translateX(-50%) translateY(-50%) rotate(45deg);
    }
    &:after {
      transform: translateX(-50%) translateY(-50%) rotate(-45deg);
    }

    @at-root #{&}-readonly {
      width: 0;
      &:before, &:after {
        content: '';
        width: 0;
      }
    }
  }
}